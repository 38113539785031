@import '../../../../../../styles/shared';

.rightPadding {
  @include mq($from: desktop) {
    padding-right: 40px;
  }
}

.leftPadding {
  @include mq($from: desktop) {
    padding-left: 40px;
  }
}

.holder {
  border-radius: 12px;
  display: block;
  max-width: 100%;
  margin: 0 auto;

  @include mq($from: desktop) {
    border-radius: 40px;
  }
}

.imagePadding {
  padding: 24px 0;
}

.paragraphPadding {
  padding-top: 12px;
}

.contentWrapper {
  margin-top: 20px;

  @include mq($from: desktop) {
    margin-top: 0;
  }
}

.imageOffset {
  margin: 40px 0;
}

.rowRightPosition {
  flex-direction: column-reverse;

  @include mq($from: desktop) {
    flex-direction: column;
  }
}

.ctaButton {
  margin-top: 24px;

  @include mq($from: desktop) {
    margin-top: 34px;
  }
}

// imageWidth = PAGE
.pageWidthHolder {
  margin: 0;
  width: 100%;
}

.pageWidthText {
  text-align: center;
}
